@import "../../../../theme.scss";

.contact {
  min-height: 100vh;
  overflow: hidden;
  justify-content: center;
  position: relative;
  padding-top: 75px;
  padding-bottom: 15px;
  background-color: rgba(43, 44, 41, 0.6);

  .wrapper {
    .title_wrapper {
      .title {
        font-size: 3rem;
        overflow: hidden;
      }
    }

    .row {
      .col-md-6 {
        .contact_form_wrapper {
          form {
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            input[type="number"] {
              -moz-appearance: textfield;
            }

            .form-element {
              .form-label {
                color: white;
                font-size: 1.2rem;
                margin-bottom: 0;
                font-weight: bold;
              }
            }
          }
        }

        .contact_link_wrapper {
          margin-top: 10vh;

          .social_medias {
            h3 {
              .social_logo {
                text-decoration: none;
                background: transparent;
                color: white;
                cursor: pointer;
                font-size: 3rem;
              }
            }
          }

          @include mobile {
            margin-top: 10vh;
          }
        }
      }
    }
  }
}

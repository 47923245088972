@import "../../../../theme.scss";
@import url("https://fonts.googleapis.com/css2?family=Imperial+Script&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Imperial+Script&family=Pushster&family=Roboto&display=swap");

.home {
  min-height: 100vh;
  overflow: hidden;
  justify-content: center;
  position: relative;
  padding-top: 75px;

  .main-content {
    .main-content-top {
      padding-bottom: 10px;
      background-color: rgba(0, 0, 0, 0.4);
      overflow: hidden;
      float: right;
      display: block;
      justify-content: center;
      position: relative;

      .top-content {
        div {
          .welcomeMSg {
            font-size: 3rem;
            color: rgba(255, 255, 255, 1);
            -webkit-text-stroke: 2px rgb(255, 255, 255);
            text-transform: uppercase;
            font-family: "Merriweather", serif;

            @include mobile {
              font-size: 1.8rem;
            }
          }
        }
      }

      .img_container {
        .col {
          div {
            height: 30vh;
            margin-bottom: 20px;
            overflow-x: hidden;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 50%;
            box-shadow: 10px 10px rgba(255, 254, 254, 0.7);
            .sushant-img {
              height: 100%;
              z-index: 1;
            }
          }
        }
      }

      .quick-btn-container {
        .col {
          .btn-logo {
            text-decoration: none;
            font-size: 1.1rem;
            font-weight: bold;
            border-radius: 5px;
            border-style: solid;
            border-color: rgba(180, 175, 175, 1);
            background-color: rgb(255, 255, 255);
            padding: 2px 5px;
            cursor: pointer;
            box-shadow: 3px 3px rgba(180, 175, 175, 1);
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .main-content-bottom {
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.4);

      .intro-text-content {
        min-height: 56vh;

        div {
          font-family: "IBM Plex Mono", monospace;
          color: $defaultColor;
          font-size: 1.5rem;

          .down-sym {
            font-size: 2.5rem;
            animation: blink 1s infinite;

            @include mobile {
              font-size: 1.5rem;
            }
          }

          @keyframes blink {
            50% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }

          @include mobile {
            font-size: 1.12rem;
          }
        }

        @include mobile {
          min-height: 45vh;
        }
      }
    }
  }
}

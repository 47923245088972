@import "../../../../theme.scss";

.menu {
  position: fixed;
  z-index: 50;

  &::before,
  &::after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(20, 21, 26, 0.6);
    border-bottom-left-radius: 200%;
    z-index: -1;
    -webkit-transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s,
      border-radius linear 0.8s;
    transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s,
      border-radius linear 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s,
      border-radius linear 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s,
      -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.6s,
      border-radius linear 0.8s;
    -webkit-transform: translateX(100%) translateY(-100%);
    transform: translateX(100%) translateY(-100%);
  }

  &::after {
    background: rgba(9, 9, 12, 0.4);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }

  &::before {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .menu_content {
    position: fixed;
    visibility: hidden;
    top: 50%;
    margin-top: 20px;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    width: 100%;
    text-align: center;

    .menu_list {
      position: relative;
      padding: 0;
      margin: 0;
      z-index: 2;

      .menu_list-item {
        position: relative;
        display: block;
        -webkit-transition-delay: 0.8s;
        transition-delay: 0.8s;
        opacity: 0;
        text-align: center;
        color: #fff;
        overflow: hidden;
        font-size: 5vh;
        font-weight: 900;
        line-height: 1.15;
        letter-spacing: 3px;
        -webkit-transform: translate(100px, 0%);
        transform: translate(100px, 0%);
        -webkit-transition: opacity 0.2s ease, -webkit-transform 0.3s ease;
        transition: opacity 0.2s ease, -webkit-transform 0.3s ease;
        transition: opacity 0.2s ease, transform 0.3s ease;
        transition: opacity 0.2s ease, transform 0.3s ease,
          -webkit-transform 0.3s ease;
        margin-top: 0;
        margin-bottom: 15px;

        a {
          position: relative;
          text-decoration: none;
          color: rgba(255, 255, 255, 0.6);
          overflow: hidden;
          cursor: pointer;
          padding-left: 5px;
          padding-right: 5px;
          font-weight: 900;
          z-index: 2;
          display: inline-block;
          text-transform: uppercase;
          -webkit-transition: all 200ms linear;
          transition: all 200ms linear;

          &:after {
            position: absolute;
            content: "";
            top: 50%;
            margin-top: -2px;
            left: 50%;
            width: 0;
            height: 0;
            opacity: 0;
            background-color: #8167a9;
            z-index: 1;
            -webkit-transition: all 200ms linear;
            transition: all 200ms linear;
          }
          &:hover:after {
            height: 4px;
            opacity: 1;
            left: 0;
            width: 100%;
          }

          &:hover {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }

  &.active {
    visibility: visible;

    .menu_content {
      visibility: visible;

      .menu_list {
        .menu_list-item {
          opacity: 1;
          -webkit-transform: translateX(0%);
          transform: translateX(0%);
          -webkit-transition: opacity 0.3s ease, color 0.3s ease,
            -webkit-transform 0.3s ease;
          transition: opacity 0.3s ease, color 0.3s ease,
            -webkit-transform 0.3s ease;
          transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
          transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease,
            -webkit-transform 0.3s ease;

          &:nth-child(0) {
            -webkit-transition-delay: 0.7s;
            transition-delay: 0.7s;
          }
          &:nth-child(1) {
            -webkit-transition-delay: 0.8s;
            transition-delay: 0.8s;
          }
          &:nth-child(2) {
            -webkit-transition-delay: 0.9s;
            transition-delay: 0.9s;
          }
          &:nth-child(3) {
            -webkit-transition-delay: 1s;
            transition-delay: 1s;
          }
          &:nth-child(4) {
            -webkit-transition-delay: 1.1s;
            transition-delay: 1.1s;
          }
          &:nth-child(5) {
            -webkit-transition-delay: 1.2s;
            transition-delay: 1.2s;
          }
          &:nth-child(6) {
            -webkit-transition-delay: 1.3s;
            transition-delay: 1.3s;
          }
          &:nth-child(7) {
            -webkit-transition-delay: 1.4s;
            transition-delay: 1.4s;
          }
          &:nth-child(8) {
            -webkit-transition-delay: 1.5s;
            transition-delay: 1.5s;
          }
          &:nth-child(9) {
            -webkit-transition-delay: 1.6s;
            transition-delay: 1.6s;
          }
          &:nth-child(10) {
            -webkit-transition-delay: 1.7s;
            transition-delay: 1.7s;
          }

          a {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }

    &::before,
    &::after {
      -webkit-transform: translateX(0%) translateY(0%);
      transform: translateX(0%) translateY(0%);
      border-radius: 0;
    }

    &::after {
      -webkit-transition-delay: 0.1s;
      transition-delay: 0.1s;
    }

    &::before {
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }
  }
}

@import "../../../../theme.scss";
@import url("https://fonts.googleapis.com/css2?family=Imperial+Script&family=Merriweather:wght@400;700&family=Pushster&family=Roboto&display=swap");

.titlebar {
  width: 100%;
  height: 75px;
  background: rgba(39, 36, 36, 1);
  color: $defaultColor;
  top: 0;
  position: fixed;
  z-index: 90;

  .nav-wrapper {
    width: inherit;
    height: inherit;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    .left {
      display: flex;
      align-items: center;

      .logo-name {
        font-family: "Merriweather", serif;
        font-size: 28px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;
      }
    }

    .right {
      .menu-btn {
        width: 38px;
        height: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        div {
          width: 100%;
          height: 4px;
          border-radius: 10px;
          background-color: $defaultColor;
          transform-origin: left;
          transition: all 0.5s ease-in-out;
        }
      }
    }
  }

  &.active {
    .menu-btn {
      div {
        &:first-child {
          background-color: white;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          transform: translateX(-50px);
          background: transparent;
          box-shadow: none;
        }
        &:last-child {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

@media only screen and (max-width: 374px) {
  .titlebar {
    .nav-wrapper {
      .right {
        .menu-btn {
          display: none;
        }
      }
    }
  }
}

$defaultColor: white;

$defaultBackground: rgba(181, 216, 226, 0.507);

$width: 768px;

@mixin mobile {
  @media (max-width: #{$width}) {
    @content;
  }
}

@mixin medium {
  @media (max-width: #{1125px}) {
    @content;
  }
}

@import "./theme.scss";

.app {
  height: 100vh;

  .bg-video {
    -o-object-fit: cover;
    object-fit: cover;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -100;
  }

  .contents {
    margin: 0;
    padding: 0;
    width: 100%;
    height: calc(100vh - 75px);
    position: relative;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

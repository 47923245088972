.experience {
  min-height: 100vh;
  overflow: hidden;
  justify-content: center;
  padding-top: 75px;
  padding-bottom: 50px;
  background-color: rgba(0, 0, 0, 0.6);

  .title_wrapper {
    .title {
      font-size: 3rem;
      overflow: hidden;
    }
  }

  .wrapper {
    .exp_timeline {
      overflow-x: visible;

      .vertical-timeline-element--work {
        overflow-x: visible;

        .vertical-timeline-element-icon {
          overflow: hidden;
          margin-top: 5px;

          .org_logo {
            height: 100%;
            width: auto;
          }
        }

        .vertical-timeline-element-content {
          overflow-x: visible;

          .vertical-timeline-element-title {
            overflow: hidden;
          }

          .vertical-timeline-element-subtitle {
            overflow: hidden;
            font-weight: bold;
            font-style: italic;
          }

          ul.descriptin_list {
            li {
              overflow-x: visible;
              margin-top: 5px;
            }
          }

          .date {
            color: white;
            opacity: 1;
            font-weight: bold;
            font-size: 1.25rem;

            @media only screen and (max-width: 1169px) {
              color: black;
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}

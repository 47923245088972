@import "../../../../theme.scss";

.skills {
  min-height: 100vh;
  justify-content: center;
  position: relative;
  background-color: rgba(24, 18, 51, 0.4);
  padding-top: 75px;
  padding-bottom: 50px;

  .title_wrapper {
    .title {
      font-size: 3rem;
      overflow: hidden;
    }
  }

  .skill_cards_group_wrapper {
    .row_cards_wrapper {
      .box {
        position: relative;
        min-width: 30vw;
        min-height: 40vh;
        perspective: 1000px;
        margin-right: 2.25rem;
        margin-left: 1.25rem;
        cursor: pointer;

        .body {
          position: absolute;
          width: 100%;
          height: 100%;
          transform-style: preserve-3d;
          transition: 0.9s ease;

          .front {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: white;
            padding: 35px;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            transform-style: preserve-3d;
          }

          .content {
            position: absolute;
            width: 100%;
            height: 100%;
            background: white;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            transform-style: preserve-3d;
            transform: rotateY(180deg);

            div {
              padding: 1.75rem;
              background: rgba(0, 0, 0, 1);
              border: 5px solid rgb(255, 255, 255);
              transform: translateZ(100px);
              color: white;
            }
          }
        }

        &:hover {
          .body {
            transform: rotateY(180deg);
          }
        }
      }
    }
  }
}

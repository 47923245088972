.education {
  min-height: 100vh;
  overflow: hidden;
  justify-content: center;
  position: relative;
  padding-top: 75px;
  padding-bottom: 50px;
  background-color: rgba(7, 50, 56, 0.3);

  .title_wrapper {
    .title {
      font-size: 3rem;
      overflow: hidden;
    }
  }

  .wrapper {
    .edu_timeline {
      .vertical-timeline-element--work {
        .vertical-timeline-element-icon {
          overflow: hidden;
          margin-top: 5px;

          .org_logo {
            height: 100%;
            width: auto;
          }
        }

        .vertical-timeline-element-content {
          .vertical-timeline-element-title {
            overflow: hidden;
          }

          .vertical-timeline-element-subtitle {
            overflow: hidden;
            font-weight: bold;
            font-style: italic;
          }

          .date {
            color: white;
            opacity: 1;
            font-weight: bold;
            font-size: 1.35rem;

            @media only screen and (max-width: 1169px) {
              color: black;
              opacity: 0.7;
            }
          }

          .landmark_img {
            max-width: 30vw;
            overflow: hidden;
          }
        }
      }
    }
  }
}

@import "../../../../theme.scss";

.projects {
  min-height: 100vh;
  justify-content: center;
  position: relative;
  background-color: rgba(45, 59, 19, 0.4);
  padding-top: 75px;
  padding-bottom: 50px;

  .title_wrapper {
    .title {
      font-size: 3rem;
      overflow: hidden;
    }
  }
}

.ol-map {
  min-width: 600px;
  min-height: 500px;
  margin: 10px;
  height: 40vw;
  width: "100%";
  position: relative;
}

.ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}

.ol-full-screen {
  top: 0.5em;
  right: 0.5em;
}

.geocoder {
  position: absolute;
  top: 10px;
  left: 60px;
  z-index: 1000;
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
